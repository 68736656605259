<template>
  <main contact>
    <h2>CONTACT</h2>
    <ul class="list">
      <li>
        <h3>일반 문의 <span>General Inquiry</span></h3>
        <p>FIT이 궁금하신가요? <br>
          안내된 연락처를 통해 문의 사항을 남겨주시면
          담당자와 제품 관련 상담이 가능합니다.</p>
        <dl>
          <dt>전화</dt>
          <dd>+82 2-2184-5532</dd>
          <dt>이메일</dt>
          <dd>
            <a href="mailto:fit_platform@samoo.com">fit_platform@samoo.com</a>
            <a href="mailto:insoon2.kang@samsung.com">insoon2.kang@samsung.com</a>
            <a href="mailto:ungbae.lee@samsung.com">ungbae.lee@samsung.com</a>
          </dd>
        </dl>
      </li>
      <li>
        <h3>방문 예약 <span>Visit reservation</span></h3>
        <p>Testbed Lab 방문 예약을 원하시나요? <br>안내된 연락처를 통해 현장 담당자에게 사전 예약을 신청해주세요.</p>
        <dl>
          <dt>전화</dt>
          <dd>+82 2-2184-5532</dd>
          <dt>이메일</dt>
          <dd>
            <a href="mailto:fit_platform@samoo.com">fit_platform@samoo.com</a>
          </dd>
        </dl>
      </li>
    </ul>
    <img src="/img/contact-img.png" alt="">
  </main>
</template>
<script>

export default {
  name: 'Contact',
  metaInfo() {
    return {
      title: 'FIT Contact',
      meta: [
        { vmid: 'title', content: 'FIT Contact', },
        { vmid: 'description', content: 'FIT Platform이 궁금하신가요? 안내된 연락처를 통해 문의 사항을 남겨주시면 담당자와 제품 관련 상담이 가능합니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[contact] { .pt(60);
  h2 { .p(80,0); .fs(40,1); .tc; }
  .list { .m(0, 36);
    > li {
      h3 { .fs(32,40); span { .ib; .ml(20); .fs(12,40); .c(#6E6E6E); .vat; font-weight: 400; }}
      h3 + p { .mt(16); .-t(#DADADA); .pt(16); .fs(20,32); .c(#6e6e6e); }
      dl { .mt(40); .fs(20,32);
        dt { .bold; }
        dd { .medium;
          a { .block;
            @{hover} { .underline; }
          }
        }
        dt + dd { .mt(8); }
        dd + dt { .mt(24); }
      }
    }
    > li + li { .mt(80); }
  }
  img { .mt(100); .block; .h(406); .wf; .min-w(100%); object-position: center; object-fit: cover; }
}

@media (min-width: @screen-tp-min) {
  [contact] {
    .list { .w(440); .mh-c; }
    img { .h(524); }
  }
}
@media (min-width: @screen-tl-min) {
  [contact] {
    h2 { .p(112,0); .fs(64,1); }
    .list { .m(0, 80); .w(auto); .grid(2, 40);
      > li {
        .cont { .p; .pl(80); }
        h3 { .fs(35); span { .fs(17); }}
        h3 + p { .fs(24,40); }
        dl { .mt(60); .fs(24,40); }
      }
      > li + li { .mt(0); }
    }
    img { .mt(160); .h(698); }
  }
}
@media (min-width: @screen-ds-min) {
  [contact] { .pt(80);
    .list { .m(0, 160); .grid(2, 80); }
    img { .h(auto); }
  }
}
@media (min-width: @screen-dl-min) {
  [contact] {
    .list { .w(1280); .mh-c; }
  }
}
</style>
